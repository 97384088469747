$(() => {
    const audioPlayer = $('.audio-sample__file')[0];
    var progressBar = document.querySelector(".progress");
    progressBar.addEventListener("click", seek);
    function updateProgress() {
        const duration = audioPlayer.duration;
        const currentTimeInSeconds = Math.floor(audioPlayer.currentTime);
        const minutesProgress = Math.floor(currentTimeInSeconds / 60);
        const secondsProgress = currentTimeInSeconds - minutesProgress * 60;
        const remainingTimeInSeconds = Math.floor(duration - currentTimeInSeconds);
        const minutesRemaining = Math.floor(remainingTimeInSeconds / 60);
        const secondsRemaining = remainingTimeInSeconds - minutesProgress * 60;

        $('.progress__filler').css('width', `${audioPlayer.currentTime / audioPlayer.duration * 100}%`);
        $('.progress__dot').css('left', `${audioPlayer.currentTime / audioPlayer.duration * 100}%`);
        $('.progress__time')[0].innerHTML = `${minutesProgress.toString()}:${secondsProgress.toString().padStart(2, '0')}`;
        $('.progress__time-remaining')[0].innerHTML = `-${minutesRemaining.toString()}:${secondsRemaining.toString().padStart(2, '0')}`;

        if (minutesRemaining === 0 && secondsRemaining === 0) {
            audioPlayer.pause();
            $('.play-pause').addClass('icomoon-play3');
            $('.play-pause').removeClass('icomoon-pause2');
            audioPlayer.currentTime = 0;
        }
    }
    if (audioPlayer) {
        $('.play-pause').on('click', () => {
            if (audioPlayer.paused) {
                audioPlayer.play();
                $('.play-pause').addClass('icomoon-pause2');
                $('.play-pause').removeClass('icomoon-play3');
            } else {
                audioPlayer.pause();
                $('.play-pause').addClass('icomoon-play3');
                $('.play-pause').removeClass('icomoon-pause2');
            }
        });
        audioPlayer.onloadeddata = () => {
            updateProgress();
        };
        audioPlayer.ontimeupdate = () => {
            updateProgress();
        };
    }
    function seek(e) {
        var percent = e.offsetX / this.offsetWidth;
        audioPlayer.currentTime = (percent * audioPlayer.duration);
        updateProgress();
    }
});
