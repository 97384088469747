import { initBrowserCheck } from "allink-core-static/js/modules/browser-recognition";

$(() => {

    const options = {};

    // init on page load
    initBrowserCheck(options);

});
